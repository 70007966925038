@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap");
:root {
  --main-blue: #0784ca;
  --main-purple: #9d49be;
  --main-green: #137724;
  --main-red: #b41e02;
}

.color-blue {
  color: var(--main-blue);
}

.color-green {
  color: var(--main-green);
}

.color-red {
  color: var(--main-red);
}

.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Cabin", sans-serif;
}

.home-text-header {
  font-size: 5vw;
}

.home-text-header > span {
  font-size: 2vw;
}

.home-text-content {
  font-size: 3vw;
}

.home-icon {
  font-size: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  margin-right: 25px;
  color: inherit;
  text-decoration: none;
}

.home-icon.linkedin:hover {
  color: var(--main-blue);
}

.home-icon.github:hover {
  color: var(--main-purple);
}

.home-icon.spotify:hover {
  color: var(--main-green);
}

.home-icon.email:hover {
  color: var(--main-red);
}

.icon-container {
  display: flex;
  flex-grow: grow;
  justify-content: between;
}

.fade {
  opacity: 0;
  animation: fadeInText 1s ease forwards;
}

.delay-1 {
  animation-delay: 1s;
}

.delay-2 {
  animation-delay: 1.5s;
}

.delay-3 {
  animation-delay: 2s;
}

.delay-4 {
  animation-delay: 2.5s;
}

/* Icon animations */
.delay-5 {
  animation-delay: 2.5s;
}

.delay-6 {
  animation-delay: 2.75s;
}

.delay-7 {
  animation-delay: 3s;
}

.delay-8 {
  animation-delay: 3.25s;
}

@keyframes fadeInText {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (max-width: 600px) {
  .home-text-header {
    font-size: 7vw;
  }

  .home-text-header > span {
    font-size: 4vw;
  }

  .home-text-content {
    font-size: 4vw;
    margin-top: 20px;
  }

  .icon-container {
    flex-wrap: wrap;
    height: 50vh;
  }

  .home-icon {
    width: 50%;
    font-size: 30vw;
    margin-top: 0px;
    margin-right: 0px;
  }
}
