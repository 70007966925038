.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.tooltip-container {
  position: fixed;
  opacity: 0.8;
  top: 50px;
  border-radius: 8px;
  right: 30px;
  padding: 12px;
  color: white;
  background: black;
  font-size: 2.5vw;
  line-height: 1;
  z-index: 100;
  white-space: normal;
  overflow-wrap: break-word;
  width: 550px;
  filter: drop-shadow(4px 4px 6px #a9a9a9);
}

.tooltip-fade {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 0.8;
    transform: translateX(0);
  }
}

@media screen and (max-width: 300px) {
  .tooltip-container {
    right: 10px;
    width: 150px;
    font-size: 2vw;
  }
}

@media screen and (max-width: 600px) {
  .tooltip-container {
    width: 250px;
    font-size: 3vw;
  }
}

@media screen and (min-width: 1100px) {
  .tooltip-container {
    width: 800px;
  }
}
